import React from 'react'
import { Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

export default () => (
  <>
    <Seo title='Privacy Policy' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle header="Privacy Policy" />
        </Section>
        <Divider />
        <Section>
          <PageTitle running='Thank you so much for visiting at our website www.zenvava.com. 
          This privacy policy sets out how uses and protects any information that you give when you use this website.
          Zenvava is committed to ensuring that your privacy is protected.
          Should we ask you to provide certain information by which you can be identified when using this website,
          then you can be assured that it will only be used in accordance with this privacy statement.
          Zenvava may change this policy from time to time by updating this page.
          You should check this page from time to time to ensure that you are happy with any changes.'
          />
        </Section> 
        <Divider />
        <Section>
          <PageTitle subheader='What we collect'
          running='We may collect the following information:'/>
          <ul>
          <li>name</li>
          <li>contact information including email address</li>
          <li>delivery information such as postcode, address and phone number</li>
          <li>other information relevant to customer surveys and/or offers</li>
          <li>cookies</li>
          </ul>
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='What we do with the information we gather'
          running='
          We require this information to understand your needs and provide you with a better service,
          and in particular for the following reasons:'
          />
          <ul>
          <li>Internal record keeping.</li>
          <li>We may use the information to improve our products and services.</li>
          <li>We may periodically send promotional emails about new products,
          special offers or other information which we think you may find interesting using the email address which you have provided.
          You can cancel promotional emails at any time if you do not need to read.</li>
          <li>From time to time, we may also use your information to contact you for confirming the order status and some delivery issues.
          We may contact you by email, phone, or mail.
          We may use the information to customize the website according to your interests.</li>
          </ul>
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Business Partners'
          running='
          We contract with outside business partners such as the makers and the couriers to offer you better experiences when you use this website.
          These outside partners collect your shipping address and phone number information to refine and improve the delivery quality.
          We do not allow these outside partners to collect any other critical information.
          All the outside cooperation partners are contractually required to maintain all the information collected for us in conformance with this policy.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Security'
          running='
          We are committed to ensuring that your information is secure.
          In order to prevent unauthorized access or disclosure, we have put in place suitable physical, electronic and managerial procedures to safeguard and secure the information we collect online.
          All the pages of this website are secured by SSL(Secure Socket Layer) technology accessible with https://www.zenvava.com.
          Also our third party payment systems such as PayPal have PCI DSS certificates.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='How we use cookies'
          running='
          A cookie is a small file which asks permission to be placed on your computer’s hard drive.
          Once you agree, the file is added and the cookie helps analyze web traffic or lets you know when you visit a particular site.
          Cookies allow web applications to respond to you as an individual.
          The web application can tailor its operations to your needs, likes and dislikes by gathering and remembering information about your preferences.'
          />
          <PageTitle running='
          We use traffic log cookies to identify which pages are being used.
          This helps us analyze data about web page traffic and improve our website in order to tailor it to customer needs.
          We only use this information for statistical analysis purposes and then the data is removed from the system.'
          />
          <PageTitle running='
          A cookie in no way gives us access to your computer or any information about you, other than the data you choose to share with us.
          You can choose to accept or decline cookies. Most web browsers automatically accept cookies,
          but you can usually modify your browser setting to decline cookies if you prefer.
          This may prevent you from taking full advantage of the website.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Links to other websites'
          running='
          Our website may contain links to other websites of interest.
          However, once you have used these links to leave our site, you should note that we do not have any control over those other websites.
          Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement.
          You should exercise caution and look at the privacy statement applicable to the website in question.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle subheader='Controlling your personal information'
          running='
          You may choose to restrict the collection or use of your personal information in the following ways:'
          />
          <ul>
          <li>whenever you are asked to fill in a form on the website,
          look for the box that you can click to indicate that you do not want the information to be used by anybody for direct marketing purposes</li>
          <li>if you have previously agreed to us using your personal information for direct marketing purposes,
          you may change your mind at any time by emailing us via Contact us page.</li>
          </ul>
          <PageTitle running='
          We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.
          You may request details of personal information which we hold about you under the Data Protection Act or Privacy Act.
          If you would like a copy of the information held on you please message us via Contact us page.
          If you believe that any information we are holding on you is incorrect or incomplete, please please message us via Contact us page as soon as possible.
          We will promptly correct any information found to be incorrect.'
          />
        </Section>
        <Divider />
        <Section>
          <PageTitle running='(Last Update: 7 August, 2020)' />
        </Section>
      </Main>
    </Stack>
  </>
)
